import React from 'react'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { GatsbyImage } from 'gatsby-plugin-image'
import { buildImageObj } from '../../utils/helpers'
import { imageUrlFor } from '../../utils/image-url'
import clientConfig from '../../utils/client-config'

function Figure(props) {
  const data = props
  return (
    <figure>
      {data.disableGatsbyImage != true ? (
        <GatsbyImage
          image={getGatsbyImageData(
            data.asset._id,
            { maxWidth: 800 },
            { ...clientConfig.sanity }
          )}
          alt={data.alt}
          className="content-image"
        />
      ) : (
        <img
          src={imageUrlFor(buildImageObj(data)).url()}
          alt={data.alt}
          sx={{
            variant: 'variants.shadow',
            maxWidth: '900px',
            margin: '0 auto',
            float: 'none',
            display: 'block',
            width: '100%',
          }}
        />
      )}
      <figcaption>{data.caption}</figcaption>
    </figure>
  )
}

export default Figure
