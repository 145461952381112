import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { CheckIcon, XCircleIcon } from '@heroicons/react/outline'
import clientConfig from '../../../utils/client-config'

const Gallery = (props) => {
  const data = props
  const [open, setOpen] = useState(false)
  const [currentImage, changeImage] = useState()
  const { column } = data // Desktop
  const { columnT } = data // Tablet
  const { columnM } = data // Mobile
  return (
    <section className="variants-gallery">
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white p-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  m-12 h-full ">
                <div>
                  <div className="mx-auto flex items-center justify-center overflow-hidden">
                    {currentImage && (
                      <GatsbyImage
                        image={getGatsbyImageData(
                          currentImage.asset.id,
                          { maxWidth: 1024 },
                          { ...clientConfig.sanity }
                        )}
                        className="w-full object-cover"
                        loading="lazy"
                        alt="none"
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {currentImage?.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {currentImage?.caption}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center float-right rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        className={`grid gap-6 justify-items-center content-center
      ${columnT === 'default' ? `grid-cols-1` : `grid-cols-${columnT}`}
      ${columnM === 'default' ? `sm:grid-cols-2` : `sm:grid-cols-${columnM}`}
      ${column === 'default' ? `lg:grid-cols-3` : `lg:grid-cols-${column}`}
      sm:p-4 lg:p-24 variantGallery`}
      >
        {data.gallery.map((image, i) => {
          function AutoLink({ to, children }) {
            if (to) {
              return <Link to={image.link}>{children}</Link>
            }
            return <div>{children}</div>
          }
          const imageTitle = image.title
          const { link } = image
          const imageCaption = image.caption
          return (
            <div className="block relative variantGalleryImage" key={i}>
              <AutoLink to={image.link}>
                <div className="text-center my-4">
                  <GatsbyImage
                    image={getGatsbyImageData(
                      image.asset.id,
                      { maxWidth: 1024 },
                      { ...clientConfig.sanity }
                    )}
                    alt={image.alt}
                    loading="lazy"
                    onClick={
                      data.disableLightbox != true
                        ? () => {
                            changeImage(image, setOpen(true))
                          }
                        : null
                    }
                  />
                  <div className="mt-3 text-center sm:mt-5">
                    <div
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {image.title}
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{image.caption}</p>
                    </div>
                  </div>
                </div>
              </AutoLink>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Gallery
