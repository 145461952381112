import { Link } from 'gatsby'
import React, { useRef, useState } from 'react'
import { useSiteMetadata } from '../../../../utils/use-site-metadata'
import Icons from '../../Icons'
import useCollapse from './useCollapse'
import Modal from 'react-modal'

function getHref(data) {
  const { pageRoute, route, link } = data
  if (data.pageRoute) {
    return `/${data.pageRoute?.slug?.current}`
  }
  if (route) {
    return route
  }
  return link
}

function AutoLink(props) {
  const { to, children, ...rest } = props
  if (!to) {
    return <></>
  }
  const anyLetters = /[A-Za-z\/]/
  if (to?.includes(':/')) {
    return (
      <a href={to} {...rest}>
        {children}
      </a>
    )
  }
  if (!to?.match(anyLetters)) {
    return (
      <a href={`tel:${to}`} {...rest}>
        {children}
      </a>
    )
  }
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  )
}

const MobileItem = (props) => {
  const {
    title,
    pageRoute,
    icon,
    route,
    kind,
    subnav,
    onExpandToggle,
    expanded,
  } = props

  const btnClassName =
    kind === 'button'
      ? 'whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700'
      : 'text-gray-500 bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'

  const iconColor =
    kind === 'button'
      ? ' group-hover:text-base text-base text-white'
      : ' group-hover:text-gray-500 text-gray-400 text-primary'

  const wrapColor = kind === 'button' ? ' bg-primary' : ''

  const maskRef = useRef(null)

  useCollapse(maskRef, expanded)
  return (
    <div>
      <div className={`-mx-3 flex items-center rounded-md  ${wrapColor}`}>
        <AutoLink className={btnClassName} to={getHref(props)}>
          {icon &&
            Icons(icon, {
              className: `flex-shrink-0 h-6 w-6 ${iconColor}`,
            })}
          <span className="ml-3 text-base font-medium ">{title}</span>
        </AutoLink>
        {subnav && (
          <button
            onClick={() => {
              onExpandToggle(!expanded)
            }}
            className="block p-2 "
          >
            {Icons(expanded ? 'FaAngleUp' : 'FaAngleDown', {
              className: `block h-5 w-5 ${iconColor}`,
            })}
          </button>
        )}
      </div>
      {/* Mask */}
      {subnav && (
        <div
          ref={maskRef}
          style={{ height: '0px' }}
          className="overflow-hidden"
        >
          {/* Container */}
          <div className="p-3 my-3 border-l-2 border-primary bg-gray-100 rounded-md ">
            {subnav?.items.map((subItem, i) => {
              const { subnav, ...props } = subItem
              return <MobileItem key={i} {...props} />
            })}
          </div>
        </div>
      )}
    </div>
  )
}

const DesktopItem = (props) => {
  const { title, pageRoute, icon, route, kind, subnav } = props

  const btnClassName =
    kind === 'button'
      ? 'whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700'
      : 'text-gray-500 bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'

  const iconColor =
    kind === 'button'
      ? ' group-hover:text-base text-base '
      : ' group-hover:text-gray-500 text-gray-400 '

  return (
    <div className="group flex items-center">
      {/* Item active: "text-gray-900", Item inactive: "text-gray-500" */}
      <div className="relative">
        <AutoLink to={getHref(props)} className={btnClassName}>
          {icon &&
            Icons(icon, {
              className: `mr-2 h-5 w-5 ${iconColor}`,
            })}
          <span>{title}</span>
          {subnav &&
            Icons('FaAngleDown', {
              className: `ml-2 h-5 w-5 ${iconColor}`,
            })}
        </AutoLink>
        {subnav && <DesktopSubMenu {...subnav} />}
      </div>
    </div>
  )
}

const DesktopSubMenu = (props) => {
  const { items } = props
  return (
    <div className="absolute hidden group-hover:block left-1/2 z-10 transform -translate-x-1/2 pt-3 px-2 w-screen max-w-md sm:px-0">
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
          {items.map((v, i) => {
            const { icon, title, subTitle } = v
            return (
              <AutoLink
                key={i}
                to={getHref(v)}
                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
              >
                {/* Heroicon name: outline/support */}
                {icon &&
                  Icons(icon, {
                    className: 'flex-shrink-0 h-6 w-6 text-indigo-600',
                  })}
                <div className="ml-4">
                  <div className="text-base mt-0 my-0 mx-0 font-medium text-gray-900">
                    {title}
                  </div>
                  {subTitle && (
                    <div className="mb-0 mx-0 mt-1 text-sm text-gray-500">
                      {subTitle}
                    </div>
                  )}
                </div>
              </AutoLink>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const ResponsiveHeader = (props) => {
  const { title, logo, showTextTitle } = useSiteMetadata()
  const [expandedItem, setExpandedItem] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className="z-[999999999]"
      >
        <div class="px-6 mt-24">
          <div className="w-full">
            <button onClick={() => setModalOpen(false)} className="float-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="#000000"
                viewBox="0 0 256 256"
              >
                <path d="M165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
              </svg>
            </button>
          </div>
          <iframe
            src="https://beta.pickaxeproject.com/axe?id=Ask_Us_Anything_V8WWY&mode=embed_gold&theme=light&opacity=100"
            width="98%"
            height="600px"
            class="bg-white z-[999999999] bg-white mt-40  rounded-md shadow-lg overflow-hidden"
          ></iframe>
        </div>
      </Modal>
      <div className="relative bg-white shadow variants-header z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            {/* Start Desktop Logo */}
            <AutoLink className="flex flex-shrink-0 inline-block" to="/">
              <img src={logo} alt={title} className="h-8 w-auto logoVariants" />
              {showTextTitle ? (
                <h1 className="pl-3 uppercase">{title}</h1>
              ) : null}
            </AutoLink>
            {/* End Desktop Logo */}
            <div className="-mr-2 -my-2 absolute right-12 top-6 md:hidden">
              <button
                onClick={() => {
                  setMenuOpen(true)
                }}
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-expanded="false"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
              {/* Button to open modal */}
              <button
                onClick={() => setModalOpen(true)}
                className="absolute -right-8 top-2 clear-none"
              >
                <svg
                  width="32"
                  height="32"
                  version="1.1"
                  viewBox="0 0 1200 1200"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="#de8246"
                >
                  <g>
                    <path d="m600 691.88c-30 0-52.5 24.375-52.5 52.5s24.375 52.5 52.5 52.5c15 0 28.125-5.625 39.375-15 9.375-9.375 15-24.375 15-39.375-1.875-28.125-24.375-50.625-54.375-50.625z" />
                    <path d="m450 406.88c18.75 1.875 37.5 3.75 58.125 7.5 3.75 0 7.5 1.875 9.375 1.875 11.25 0 13.125-7.5 15-16.875 9.375-31.875 33.75-50.625 65.625-50.625 24.375 0 45 11.25 54.375 30 7.5 15 5.625 33.75-9.375 50.625-9.375 13.125-22.5 24.375-35.625 33.75-16.875 13.125-35.625 26.25-46.875 48.75-13.125 30-13.125 69.375-13.125 105 0 9.375 3.75 13.125 13.125 13.125h33.75 7.5 13.125 11.25 1.875c5.625 0 9.375-1.875 11.25-3.75 3.75-3.75 3.75-9.375 3.75-13.125 0-16.875 0-33.75 1.875-48.75 1.875-18.75 13.125-28.125 26.25-41.25l3.75-3.75c3.75-3.75 7.5-5.625 11.25-9.375 35.625-30 75-65.625 75-116.25 0-33.75-16.875-69.375-45-93.75-30-26.25-71.25-39.375-116.25-39.375-76.875 0-159.38 45-166.88 129.38 1.875 9.375 7.5 15 16.875 16.875z" />
                    <path d="m277.5 67.5c-142.5 0-258.75 114.38-258.75 256.88v755.62c0 22.5 13.125 41.25 33.75 48.75 5.625 1.875 13.125 3.75 20.625 3.75 15 0 28.125-5.625 37.5-16.875l144.38-150h669.38c142.5 0 258.75-116.25 258.75-258.75v-639.38zm813.75 641.25c0 93.75-75 168.75-168.75 168.75h-706.88l-106.88 112.5v-665.62c0-93.75 75-168.75 168.75-168.75h815.62v553.12z" />
                  </g>
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10">
              {props.navMenuItems?.map((v, i) => (
                <DesktopItem key={i} {...v} />
              ))}
            </nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-10">
              {props.contactMenuItems?.map((v, i) => (
                <DesktopItem key={i} {...v} />
              ))}
            </div>
            <div>{props.variantObjects}</div>
          </div>
        </div>

        {menuOpen && (
          <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <AutoLink className="inline-block" to="/">
                      <img
                        src={logo}
                        alt={title}
                        className="h-8 w-auto logoVariants mobile-logoVariants"
                      />
                    </AutoLink>
                  </div>
                  <div className="-mr-2">
                    <button
                      onClick={() => {
                        setMenuOpen(false)
                      }}
                      type="button"
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    >
                      <span className="sr-only">Close menu</span>
                      {/* Heroicon name: outline/x */}
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-1">
                    {props.navMenuItems.map((v, i) => (
                      <MobileItem
                        onExpandToggle={() => {
                          setExpandedItem(i === expandedItem ? null : i)
                        }}
                        expanded={expandedItem === i}
                        key={i}
                        {...v}
                      />
                    ))}
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                {props.contactMenuItems.map((v, i) => (
                  <MobileItem key={i} {...v} />
                ))}
              </div>
            </div>
          </div>
        )}
        {/* Button to open modal */}
        <button
          onClick={() => setModalOpen(true)}
          className="absolute right-4 top-8 hidden md:block"
        >
          <svg
            width="32"
            height="32"
            version="1.1"
            viewBox="0 0 1200 1200"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="#de8246"
          >
            <g>
              <path d="m600 691.88c-30 0-52.5 24.375-52.5 52.5s24.375 52.5 52.5 52.5c15 0 28.125-5.625 39.375-15 9.375-9.375 15-24.375 15-39.375-1.875-28.125-24.375-50.625-54.375-50.625z" />
              <path d="m450 406.88c18.75 1.875 37.5 3.75 58.125 7.5 3.75 0 7.5 1.875 9.375 1.875 11.25 0 13.125-7.5 15-16.875 9.375-31.875 33.75-50.625 65.625-50.625 24.375 0 45 11.25 54.375 30 7.5 15 5.625 33.75-9.375 50.625-9.375 13.125-22.5 24.375-35.625 33.75-16.875 13.125-35.625 26.25-46.875 48.75-13.125 30-13.125 69.375-13.125 105 0 9.375 3.75 13.125 13.125 13.125h33.75 7.5 13.125 11.25 1.875c5.625 0 9.375-1.875 11.25-3.75 3.75-3.75 3.75-9.375 3.75-13.125 0-16.875 0-33.75 1.875-48.75 1.875-18.75 13.125-28.125 26.25-41.25l3.75-3.75c3.75-3.75 7.5-5.625 11.25-9.375 35.625-30 75-65.625 75-116.25 0-33.75-16.875-69.375-45-93.75-30-26.25-71.25-39.375-116.25-39.375-76.875 0-159.38 45-166.88 129.38 1.875 9.375 7.5 15 16.875 16.875z" />
              <path d="m277.5 67.5c-142.5 0-258.75 114.38-258.75 256.88v755.62c0 22.5 13.125 41.25 33.75 48.75 5.625 1.875 13.125 3.75 20.625 3.75 15 0 28.125-5.625 37.5-16.875l144.38-150h669.38c142.5 0 258.75-116.25 258.75-258.75v-639.38zm813.75 641.25c0 93.75-75 168.75-168.75 168.75h-706.88l-106.88 112.5v-665.62c0-93.75 75-168.75 168.75-168.75h815.62v553.12z" />
            </g>
          </svg>
        </button>
      </div>
    </>
  )
}

export default ResponsiveHeader
