import React from "react"
import ResponsiveHeader from "gatsby-theme-tailwindui/src/components/tailwindUI/headers/responsiveHeader/responsiveHeader"
import { Helmet } from "react-helmet"

const SiteHeader = ({ navMenuItems, contactMenuItems }) => {
  const navItems = navMenuItems
  const contactItems = contactMenuItems
  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`var _iub = _iub || [];
_iub.csConfiguration = {"invalidateConsentWithoutLog":true,"consentOnContinuedBrowsing":false,"lang":"en","floatingPreferencesButtonDisplay":"bottom-right","siteId":2405228,"cookiePolicyId":66644544, "banner":{ "closeButtonRejects":true,"acceptButtonDisplay":true,"customizeButtonDisplay":true,"explicitWithdrawal":true,"position":"float-top-center","acceptButtonColor":"#de7b3a","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#de7b3a","rejectButtonCaptionColor":"white","textColor":"black","backgroundColor":"white","brandBackgroundColor":"none","brandTextColor":"black" }};`}
        </script>
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/iubenda_cs.js"
          charset="UTF-8"
          async
        />
      </Helmet>
      <ResponsiveHeader
        navMenuItems={navItems}
        contactMenuItems={contactItems}
      />
    </>
  )
}

export default SiteHeader
