/* eslint-disable */
import React from "react"
import FourColumnFooter from "gatsby-theme-tailwindui/src/components/tailwindUI/footers/fourColumnFooter"

function privacyPolicy() {
  return {
    __html:
      '<a href="https://www.iubenda.com/privacy-policy/66644544" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>',
  }
}

function cookiePolicy() {
  return {
    __html:
      '<a href="<a href="https://www.iubenda.com/privacy-policy/66644544/cookie-policy" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>',
  }
}

function terms() {
  return {
    __html:
      '<a href="https://www.iubenda.com/terms-and-conditions/66644544" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Terms and Conditions ">Terms and Conditions</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>',
  }
}

const SiteFooter = () => {
  const add = "test"
  return (
    <>
      <FourColumnFooter />
      <ul className="flex bg-[#2d3748] justify-center">
        <li
          dangerouslySetInnerHTML={privacyPolicy()}
          className="bg-[#2d3748] text-[#718096] text-center p-2"
        />
        <li
          dangerouslySetInnerHTML={cookiePolicy()}
          className="bg-[#2d3748] text-[#718096]  text-center p-2"
        />
        <li
          dangerouslySetInnerHTML={terms()}
          className="bg-[#2d3748] text-[#718096]  text-center p-2"
        />
      </ul>
    </>
  )
}

export default SiteFooter
