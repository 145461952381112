import React from 'react'
import Figure from '../components/block-content/Figure'
import Spacer from '../components/block-content/Spacer'
import ButtonBlock from '../components/block-content/ButtonBlock'
import SanityScript from '../components/block-content/Script'
import Gallery from '../components/tailwindUI/gallery/standardGallery'
import Video from '../components/video/video'
import ImageBlockGrid from '../components/tailwindUI/gridList/imageBlockGridV1'

const serializers = {
  decorators: {
    left: (props) => <span className="block text-left">{props.children}</span>,
    center: (props) => (
      <span className="block text-center">{props.children}</span>
    ),
    right: (props) => (
      <span className="block text-right">{props.children}</span>
    ),
  },
  types: {
    block(props) {
      switch (props.node.style) {
        case 'large':
          return <p>{props.children}</p>

        case 'xlarge':
          return <p className="text-4xl">{props.children}</p>

        case 'xxlarge':
          return <p className="text-6xl">{props.children}</p>

        case 'h1':
          return <h1>{props.children}</h1>

        case 'h2':
          return <h2>{props.children}</h2>

        case 'h3':
          return <h3>{props.children}</h3>

        case 'h4':
          return <h4>{props.children}</h4>

        case 'blockquote':
          return <blockquote>{props.children}</blockquote>

        default:
          return <p>{props.children}</p>
      }
    },
    figure(props) {
      return <Figure {...props.node} />
    },
    clear(props) {
      return <Spacer {...props.node} />
    },
    spacer(props) {
      return <Spacer {...props.node} />
    },
    buttonBlock(props) {
      return <ButtonBlock {...props.node} />
    },
    script(props) {
      return <SanityScript {...props.node} />
    },
    video(props) {
      return <Video {...props.node} />
    },
    gallery(props) {
      return <Gallery {...props.node} />
    },
    imageBlockGrid(props) {
      return <ImageBlockGrid {...props.node} />
    },
  },
}

export default serializers
