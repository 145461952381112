import React from 'react'

const Video = ({
  url,
  videoTitle,
  iframeClasses,
  containerClasses,
  height,
  width,
  service,
}) => (
  <>
    <div className={`${containerClasses}`}>
      {service === 'vimeo' ? (
        <iframe
          title={url}
          src={url}
          frameBorder="0"
          allow="autoplay"
          allowFullScreen
          height={height || '100%'}
          width={width || '100%'}
          className="max-w-full mx-auto"
        />
      ) : (
        <iframe
          src={url}
          title={videoTitle}
          className={`${iframeClasses} videoVariants mx-auto`}
          height={height || 'auto'}
          width={width}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          muted="true"
        />
      )}
    </div>
  </>
)
export default Video
