import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import clientConfig from 'gatsby-theme-tailwindui/src/utils/client-config'
import Figure from './Figure'
import Spacer from './Spacer'
import ButtonBlock from './ButtonBlock'
import SanityScript from './Script'
import Gallery from '../tailwindUI/gallery/standardGallery'
import ImageGridBlocks from '../../studio/schema/gridList/imageBlockGridV1'

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return <h1 className="prose">{props.children}</h1>

        case 'h2':
          return <h2 className="prose">{props.children}</h2>

        case 'h3':
          return <h3 className="prose">{props.children}</h3>

        case 'h4':
          return <h4 className="prose">{props.children}</h4>

        case 'blockquote':
          return <blockquote className="prose">{props.children}</blockquote>

        default:
          return (
            <div className="max-w-prose mx-auto">
              <p className="prose">{props.children}</p>
            </div>
          )
      }
    },
    figure(props) {
      return <Figure {...props.node} />
    },
    clear(props) {
      return <Spacer {...props.node} />
    },
    spacer(props) {
      return <Spacer {...props.node} />
    },
    buttonBlock(props) {
      return <ButtonBlock {...props.node} />
    },
    script(props) {
      return <SanityScript {...props.node} />
    },
    gallery(props) {
      return <Gallery {...props.node} />
    },
    imageBlockGrid(props) {
      return <ImageGridBlocks {...props.node} />
    },
  },
}

const BlockContent = ({ blocks }) => (
  <BaseBlockContent
    blocks={blocks}
    serializers={serializers}
    {...clientConfig.sanity}
  />
)

export default BlockContent
