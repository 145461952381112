import React from 'react'
import { Link } from 'gatsby'

// import { buildImageObj } from '../../lib/helpers'
// import { imageUrlFor } from '../../lib/image-url'

function ButtonBlock({ parentProps, ...props }) {
  const sanityProps = parentProps || props
  return (
    <div className="my-4">
      {sanityProps.buttons.map((btn) => (
        <Link key={btn.key} to={btn.href} className="no-underline">
          {btn.buttonStyle === 'dark' ? (
            <button
              type="button"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mx-2"
            >
              {btn.buttonTxt}
            </button>
          ) : (
            <button
              type="button"
              className="inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {btn.buttonTxt}
            </button>
          )}
        </Link>
      ))}
    </div>
  )
}

export default ButtonBlock
