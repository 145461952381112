// import { format } from 'date-fns'

export function cn(...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map((edge) => edge.node)
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current
}

export function getBlogUrl(publishedAt, slug) {
  return `/blog/${slug.current || slug}/`
}

export function getServiceUrl(publishedAt, slug) {
  return `/services/${slug.current || slug}/`
}

export function buildImageObj(source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  }

  if (source?.crop) imageObj.crop = source.crop
  if (source?.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}
