import React from 'react'
import { ThemeProvider } from 'theme-ui'
import SiteContainer from './site-container'
import SiteHeader from './header'
import Main from './main'
import ContentContainer from './content-container'
import Footer from './footer'
import theme from '../theme'

const Layout = ({ children, navMenuItems, contactMenuItems }) => {
  const navItems = navMenuItems
  return (
    <ThemeProvider theme={theme}>
      <SiteContainer>
        <a
          href="/LCOF-Fund-Overview"
          className="!pointer-events-auto hover:!cursor-pointer"
        >
          <div className="bg-[#de8246] hover:!cursor-pointer !pointer-events-auto	 hover:bg-[#a76234] text-white font-bold py-5 text-center">
            Lateral Capital Opportunity Fund NOW OPEN TO ACCREDITED INVESTORS!!
            Click HERE for more information!
          </div>
        </a>
        <SiteHeader
          navMenuItems={navItems}
          contactMenuItems={contactMenuItems}
        />
        <Main>
          <ContentContainer>{children}</ContentContainer>
        </Main>
        <Footer />
      </SiteContainer>
    </ThemeProvider>
  )
}

export default Layout
