// https://tailwindui.com/components/marketing/sections/footers
// 4-column with company mission
import React, { useContext } from 'react'
import { useColorMode } from 'theme-ui'
import { Link, StaticQuery, graphql } from 'gatsby'
import BaseBlockContent from '@sanity/block-content-to-react'
import PrimaryLink from './primaryLink'
import BlockContent from '../../block-content'
import { NavContext } from '../../../utils/nav-context'
import clientConfig from '../../../utils/client-config'
import { buildImageObj } from '../../../utils/helpers'
import { imageUrlFor } from '../../../utils/image-url'
import serializers from '../../../utils/serializers'
import { useSiteMetadata } from '../../../utils/use-site-metadata'

const Footer = ({ data }) => {
  const { developer, developerLink, title } = useSiteMetadata()
  const [colorMode, setColorMode] = useColorMode('light')
  const footer = data.sanityFooter
  const { disclaimer } = data.sanityFooter || []
  const logo = colorMode === 'light' ? footer.logo : footer.logoDark
  const logoAlt = colorMode === 'light' ? footer.logo.alt : footer.logoDark?.alt
  const { footerSection } = footer
  const { additionalLinks } = useSiteMetadata()
  const footerSections = footerSection.slice(1)
  return (
    <>
      <footer
        id="site-footer"
        className="background"
        aria-labelledby="footerHeading"
      >
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <img src={imageUrlFor(buildImageObj(logo))} alt={logoAlt} />
              <BaseBlockContent
                blocks={footer.footerSection[0]._rawText}
                serializers={serializers}
                {...clientConfig.sanity}
              />
            </div>
            <div className="mt-12 grid grid-cols-3 gap-8 xl:mt-0 xl:col-span-2">
              {footerSections &&
                footerSections.map((section, i) => {
                  const { menu } = section
                  const text = section._rawText
                  return (
                    <div key={i}>
                      <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                        {section.title}
                      </h3>
                      {section._rawText ? <BlockContent blocks={text} /> : null}
                      <ul className="mt-4 space-y-4">
                        {menu
                          ? menu.items.map((menuItem, i) => (
                              <li
                                className="text-base text-gray-500 hover:text-gray-900"
                                key={i}
                              >
                                <PrimaryLink key={`cta_${i}`} {...menuItem} />
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="block text-gray-500 mt-5 text-justify">
            {disclaimer || null}
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-base text-gray-600 xl:text-center">
              © {new Date().getFullYear()} {title} | Site Design:{' '}
              <a href={developerLink}> {developer || '3200.Pro'}</a>
              {additionalLinks
                ? additionalLinks.map((addtionalLink, i) => (
                    <span key={i} className="pl-2">
                      | <PrimaryLink key={`cta_${i}`} {...addtionalLink} />
                    </span>
                  ))
                : null}
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default function FourColumnFooter(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          sanityFooter(_id: { regex: "/(drafts.|)footer/" }) {
            disclaimer
            logoDark {
              asset {
                _id
                url
              }
              alt
            }
            logo {
              asset {
                _id
                url
              }
              alt
            }
            footerSection {
              _key
              title
              hideTitle
              footerLogo
              _rawText
              menu {
                items {
                  _key
                  title
                  kind
                  link
                  route
                  relativeNavItem
                  subnav {
                    ... on SanityMenu {
                      title
                    }
                    items {
                      ... on SanityNavItem {
                        title
                        subTitle
                        route
                        link
                        kind
                        icon
                        relativeNavItem
                      }
                    }
                  }
                  pageRoute {
                    ... on SanityRoute {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                  }
                }
              }
              style
              title
            }
          }
        }
      `}
      render={(data) => <Footer data={data} {...props} />}
    />
  )
}
