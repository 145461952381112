export default {
  title: 'Image Grid Block',
  name: 'imageBlockGrid',
  description:
    'Creates a grid of blocks that include pre-title, title, intro text, featured image, and link',
  type: 'object',
  fields: [
    {
      name: 'block',
      title: 'Block',
      type: 'array',
      of: [
        {
          title: 'Card',
          type: 'card',
        },
      ],
    },
  ],
}
