import React from 'react'
import { Link, navigate } from 'gatsby'

const doNavigate = (target) => {
  if (!target || !target.length) {
    return
  }
  const internal = /^\/(?!\/)/.test(target)
  if (internal) {
    navigate(target)
  } else {
    window.location = target
  }
}

const PrimaryLink = (props) => {
  const PROPS = props
  let link = (PROPS.route && `${PROPS.route}`) || PROPS.link || '#'
  if (PROPS.pageRoute && PROPS.pageRoute.slug && PROPS.pageRoute.slug.current) {
    link = PROPS.pageRoute.slug.current
  }

  if (PROPS.kind === 'button') {
    return (
      <>
        <button
          type="button"
          id="navAction"
          className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          onClick={() => doNavigate(link)}
        >
          {PROPS.title}
        </button>
      </>
    )
  }

  // External
  if (PROPS.link) {
    return (
      <a
        href={PROPS.link}
        className="text-base font-medium text-gray-500 hover:text-gray-900"
        target="_blank"
        rel="noopener noreferrer"
      >
        {PROPS.title}
      </a>
    )
  }

  return (
    <>
      {/* {isSubNav ? 'subNav' : null} */}
      <Link
        to={PROPS.relativeNavItem === true ? `${link}` : `/${link}`}
        className="text-base font-medium text-gray-500 hover:text-gray-900"
      >
        {PROPS.title}
      </Link>
      {PROPS.subnav ? <Subnav subNav={PROPS.subnav} /> : null}
    </>
  )
}

export default PrimaryLink
