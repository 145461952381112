import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import clientConfig from '../../../utils/client-config'

const ImageGridBlocks = (props) => {
  console.log('image grid block props', props)
  const { block } = props
  const p = props
  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 prose-unprose">
      {block.map((card) => (
        <div
          key={card._key}
          className="flex flex-col rounded-lg shadow-lg overflow-hidden"
        >
          <div className="flex-shrink-0">
            <GatsbyImage
              image={getGatsbyImageData(
                card?.figure?.asset?.id,
                { maxWidth: 1024 },
                { ...clientConfig.sanity }
              )}
              className="h-48 w-full object-cover"
              alt={card?.figure?.alt}
              loading="lazy"
            />
          </div>
          <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1">
              <p className="text-sm font-medium text-indigo-600">
                {card.preTitle}
              </p>
              <p className="text-xl font-semibold text-gray-900">
                {card.title}
              </p>
              <p className="mt-3 text-base text-gray-500">{card.text}</p>
            </div>
          </div>
        </div>
      ))}
    </ul>
  )
}

export default ImageGridBlocks
