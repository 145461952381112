import { useEffect } from "react"

const lerp = (x, y, a) => x * (1 - a) + y * a

export default (maskRef, expanded) => {
  useEffect(() => {
    const maskElem = maskRef?.current

    if (!maskElem) {
      return
    }

    if (maskElem.offsetHeight === 0 && !expanded) {
      return
    }

    const iniHeight = expanded ? 0 : maskElem.scrollHeight
    const endHeight = expanded ? maskElem.scrollHeight : 0

    const steps = 20
    const delay = 1000 / 60
    Array(steps + 1)
      .fill(0)
      .forEach((_, i) => {
        const dt = i / steps
        const height = lerp(iniHeight, endHeight, dt * (2 - dt))

        setTimeout(() => {
          if (dt === 1 && expanded) {
            maskElem.style.height = "auto"
          } else {
            maskElem.style.height = height + "px"
          }
        }, i * delay)
      })
  }, [expanded, maskRef])
}
